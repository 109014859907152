<template>
	<div>
		<section class="header-bg" style="min-height: 255px;">
		</section>
		<section class="container-1200" style="margin-top: 40px;">
			<!-- <el-breadcrumb separator=">">
				<el-breadcrumb-item :to="{ path: '/index' }">Home</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ path: '/works' }">How it works</el-breadcrumb-item>
			</el-breadcrumb> -->
			<div style="height: 20px;">
				<p class="title-hr"><strong style="font-size: 28px;font-family: 'Bebas Neue', cursive;">How it works</strong></p>
			</div>
		</section>
		<section class="container-1200" style="width:100%;margin: 40px auto;">
			<div class="first">
				<div class="first_left">
					<img :src="require('@/assets/images/worksLeft.png')" alt="">
				</div>
				<div class="first_right">
					<h2 style="font-size: 30px;">How it works</h2>
					<p style="margin-top: 15px;text-align: justify; hyphens: auto;" lang="en">Contrimetric Plug-in is an invaluable research insights tool designed
						to serve the academic community. It offers a convenient and free tool for researchers and
						academic institutions to showcase citations, and recommend relevant research articles tailored
						to the interests and needs of readers. By providing easy access to these selected resources,
						Contrimetric Plug-in aims to streamline the process of literature review and keep scholars
						updated on the latest developments in their respective fields.
					</p>
					<!-- <p style="margin-top: 15px;">Embed a Contrimetric widget into your journals, so authors and readers
						can easily see how many
						citations the publications have received, and see the academic values of each citations.</p> -->
				</div>
			</div>

		</section>


		<section class="container-1200" style="width:100%;height:420px;margin: 40px auto;margin-top: 80px;">

			<div class="second">
				<div class="second_left">
					<h2 style="margin:10px 0px;font-size: 30px;">For readers</h2>
					<ul>
						<li>Find interesting articles to advance your work and help others.</li>
						<li>Make new connections between disciplines -find articles in journals you may not regularly
							read.</li>
						<li>Discover new developments in your field and related disciplines.</li>
						<li>Select articles from the world’s leading peer-reviewed journals and research news from
							our publishing partners.</li>
					</ul>
				</div>
				<div class="second_right">
					<h2 style="margin:10px 0px;font-size: 30px;">For Publisher</h2>
					<ul>
						<li style="font-weight: 700;">ORGANIC DISCOVERY</li>
						<p>Recirculate traffic between your journals, blog and book sites by recommending the right
							content to the right visitor at the right time.</p>
						<li style="font-weight: 700;">PROMOTED DISCOVERY</li>
						<p>Surface content from your journals to a relevant audience of over 100M unique monthly
							visitors across hundreds of premium scholarly sites</p>
					</ul>
				</div>
			</div>
		</section>
		<!-- 插件 -->
		<!-- <section class="container-1200" style="width:100%;margin: 40px auto;">
			<img :src="require('@/assets/images/widget.png')" alt="" />
		</section> -->
		<section class="container-1200" style="width:100%;margin: 40px auto;margin-bottom: 0px;">
			<div class="worksBottom" style="margin-top: 100px;">
				<img :src="require('@/assets/images/index_widget.png')" alt="" />
			</div>
		</section>

	</div>

</template>
<script>
	export default {
		name: "works",
		data() {
			return {};
		},
		watch: {},
		created() {

		},
		mounted() {},
		methods: {

		},
	}
</script>

<style lang="scss" scoped>
	.first {
		display: flex;
	}

	.first_left {
		flex: 1;
	}

	.first_left img {
		width: 85%;
	}

	.first_right {
		flex: 1;
		margin-top: 50px;
		line-height: 26px;
	}

	.second {
		display: flex;
		position: relative;
	}

	.second_left {
		width: 42%;
		line-height: 26px;
		border: 2px dotted hsl(183.72deg, 77.54%, 63.33%);
		padding: 30px 20px 30px 30px;
	}

	li {
		list-style-type: disc;
	}

	ul li::marker {
		color: hsl(183.72deg, 77.54%, 63.33%);
		/* 设置列表项圆圈的颜色为红色 */
	}

	.second_right {
		width: 42%;
		line-height: 26px;
		border: 2px dotted hsl(183.72deg, 77.54%, 63.33%);
		padding: 30px 20px 30px 30px;
		margin-left: 30px;
		position: absolute;
		right: 115px;
		top: 230px;
		margin-bottom: 100px;
	}

	.worksBottom img {
		width: 100%
	}
</style>